import {Link, useParams} from "react-router-dom";
import axios from "axios";
import {useState, useEffect} from "react";

const ProcessingDetailed = () => {
  // COntains the orders currently paid for
  const [orders, setOrders] = useState([]);
  const searchParams = useParams();

  async function fetchOrders() {
    try {
      let res = await axios.get(`/orders/${searchParams.id}`);
      setOrders(res.data.orders);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <div className="pt-[100px] md:pt-32">
      <p>Order </p>

      <p>Order Information</p>

      {orders && orders.length && (
        <div className="flex flex-col">
          <p> {orders[0].customer_number} </p>
          <p> {orders[0].location} </p>
          <p> {orders[0].order_status} </p>
        </div>
      )}

      <p>
        <Link to={`/payment/${searchParams.id}`}>View payment Information</Link>
      </p>

      {orders.map((cat) => (
        <div>
          <ul className="flex justify-around">
            <li key={cat.id}> Bag Name {cat.bag_name}</li>
            <li key={cat.id}> Ordered Quantity {cat.quantity}</li>
          </ul>
        </div>
      ))}
    </div>
  );
};

export default ProcessingDetailed;
