import axios from "axios";

export async function tokenSet(token) {
  window.localStorage.setItem("token", token);
  axios.defaults.headers.common["Authorization"] =
    "Bearer " + localStorage.getItem("token");
}

export async function tokenDel() {
  delete axios.defaults.headers.common["Authorization"];
  window.localStorage.removeItem("token");
}
