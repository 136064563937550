import React, {createContext, useState, useEffect} from "react";
import axios from "axios";
export const DeliveryContext = createContext();

const DeliveryProvider = ({children}) => {
  // products state
  const [deliveries, setDeliveries] = useState([]);
  // fetch products
  useEffect(() => {
    const fetchProducts = async () => {
      const response = await axios.get(
        "/fetch/deliveries",
      );
      setDeliveries(response.data);
    };
    fetchProducts();
  }, []);

  return (
    <DeliveryContext.Provider value={{deliveries}}>
      {children}
    </DeliveryContext.Provider>
  );
};

export default DeliveryProvider;
