import {Link, useParams} from "react-router-dom";
import axios from "axios";
import {useState, useEffect} from "react";

const PaymentInfo = () => {
  // COntains the orders currently paid for
  const [payment, setPayment] = useState([]);
  const searchParams = useParams();

  async function fetchPaymentInfo() {
    try {
      let res = await axios.get(`/payments/${searchParams.id}`);
      setPayment(res.data.orders);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    fetchPaymentInfo();
  }, []);

  return (
    <div className="pt-[100px] md:pt-32">
      <p>Payment </p>

      <p>Payment Information</p>

      <p> Transaction ID {payment.MpesaReceiptNumber} </p>
      <p> Amount paid {payment.amount} </p>
      <p> Date {payment.creation_date}</p>
    </div>
  );
};

export default PaymentInfo;
