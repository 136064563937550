import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import ProductProvider from "./contexts/ProductContext";
import SidebarProvider from "./contexts/SidebarContext";
import CartProvider from "./contexts/CartContext";
import DeliveryProvider from "./contexts/DeliveriesContext";
import PriceProvider from "./contexts/TotalPriceContext";
import DeliveryFeeProvider from "./contexts/DeliveryFeeContext";
import DoorstepLocationsProvider from "./contexts/DoorstepLocationsContext";
import LogProvider from "./contexts/LoggedContext";
import axios from "axios";

axios.defaults.withCredentials = true;

// in case the page is reloaded, and the person is logged in, keep the authorization header
if (localStorage.getItem("token")) {
  axios.defaults.headers.common["Authorization"] =
    "Bearer " + localStorage.getItem("token");
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <SidebarProvider>
    <CartProvider>
      <ProductProvider>
        <DeliveryProvider>
          <DeliveryFeeProvider>
            <PriceProvider>
              <DoorstepLocationsProvider>
                <LogProvider>
                  <React.StrictMode>
                    <App />
                  </React.StrictMode>
                </LogProvider>
              </DoorstepLocationsProvider>
            </PriceProvider>
          </DeliveryFeeProvider>
        </DeliveryProvider>
      </ProductProvider>
    </CartProvider>
  </SidebarProvider>,
);
