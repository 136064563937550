import {useNavigate, Link} from "react-router-dom";
import axios from "axios";
import {useState, useEffect} from "react";

const Bags = () => {
  // mostly contain links to admin functionality. All admin endpoints are relevant here
  const [bags, setBags] = useState([]);
  const [input_open, setInputOpen] = useState(false);
  const [category, setCategory] = useState(0);
  const [price, setPrice] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [name, setName] = useState("");
  const [categories, setCategories] = useState([]);

  async function fetchBags() {
    try {
      let res = await axios.get("/bags");
      setBags(res.data);
    } catch (e) {
      console.log(e);
    }
  }

  async function deleteBag(id) {
    try {
      let res = await axios.delete(`/delete/bag/${id}`);
      await fetchBags();
    } catch (e) {
      console.log(e);
    }
  }

  async function addBag(e) {
    e.preventDefault();
    if (category && quantity && price && name) {
      try {
        let res = await axios.post("/new/bag", {
          name,
          price,
          quantity,
          category_id: category,
        });
        fetchBags();
      } catch (e) {
        console.log(e);
      }
    }
    // return error since category is not validated
  }

  async function fetchCategories() {
    try {
      let result = await axios.get("/categories");
      console.log(result.data);
      setCategories(result.data);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    fetchBags();
    fetchCategories();
  }, []);

  return (
    <div className="pt-[100px] md:pt-32">
      <p>Bags</p>

      {bags.map((cat) => (
        <div>
          <ul className="flex justify-around">
            <li key={cat.id}>
              <Link to={`/bags/${cat.id}`}> {cat.name} </Link>
            </li>
            <li key={cat.id}> {cat.price}</li>
            <li key={cat.id}> {cat.quantity}</li>
            <li key={cat.id}> {cat.category_id}</li>
            <button onClick={() => deleteBag(cat.id)}> Delete </button>
          </ul>
        </div>
      ))}

      <button onClick={() => setInputOpen(!input_open)}> Add </button>

      {input_open ? (
        <div className="w-full max-w-xs">
          <form
            className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
            onSubmit={addBag}
          >
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                for="username"
              >
                Bag Name
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                for="username"
              >
                Bag Price
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                value={price}
                onChange={(e) => {
                  setPrice(e.target.value);
                }}
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                for="username"
              >
                Stock
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                value={quantity}
                onChange={(e) => {
                  setQuantity(e.target.value);
                }}
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                for="username"
              >
                Bag Category
              </label>

              <select
                id="countries"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                onChange={(e) => setCategory(e.target.value)}
              >
                <option selected>Choose a category</option>
                {categories.map((mp) => (
                  <option value={mp.id}>{mp.category_name}</option>
                ))}
              </select>
            </div>
            <div className="flex items-center justify-between">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
              >
                Submit
              </button>
            </div>
          </form>
          <p className="text-center text-gray-500 text-xs">
            &copy;2020 Acme Corp. All rights reserved.
          </p>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default Bags;
