import React, {createContext, useState, useEffect} from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";

export const CartContext = createContext();

const CartProvider = ({children}) => {
  // cart state
  const [cart, setCart] = useState([]);
  // item amount state
  const [itemAmount, setItemAmount] = useState(0);
  // total price state
  const [total, setTotal] = useState(0);

  async function fetchTotal() {
    const value = await AsyncStorage.getItem("my-total");
    if (value) {
      setTotal(value);
    }
  }

  async function fetchItemAmount() {
    const value = await AsyncStorage.getItem("my-itemAmount");
    if (value) {
      setItemAmount(value);
    }
  }

  async function fetchCart() {
    const value = await AsyncStorage.getItem("my-cart");
    if (value) {
      setCart(JSON.parse(value));
    }
  }

  // update asyncstorage when states change
  useEffect(() => {
    if (itemAmount !== 0) {
      AsyncStorage.setItem("my-itemAmount", `${itemAmount}`);
    }
  }, [itemAmount]);

  useEffect(() => {
    if (total !== 0) {
      AsyncStorage.setItem("my-total", `${total}`);
    }
  }, [total]);

  useEffect(() => {
    if (cart.length) {
      AsyncStorage.setItem("my-cart", JSON.stringify(cart));
    } else {
      console.log(cart);
    }
  }, [cart]);

  // read state on each mount

  useEffect(() => {
    const total = cart.reduce((accumulator, currentItem) => {
      return accumulator + currentItem.price * currentItem.amount;
    }, 0);

    setTotal(total);
  });

  useEffect(() => {
    fetchCart();
    fetchTotal();
    fetchItemAmount();
  }, []);

  // update item amount
  useEffect(() => {
    if (cart) {
      const amount = cart.reduce((accumulator, currentItem) => {
        return accumulator + currentItem.amount;
      }, 0);
      setItemAmount(amount);
    }
  }, [cart]);

  // add to cart
  const addToCart = (product, id) => {
    const newItem = {...product, amount: 1};
    // check if the item is already in the cart
    const cartItem = cart.find((item) => {
      return item.id === id;
    });
    if (cartItem) {
      const newCart = [...cart].map((item) => {
        if (item.id === id) {
          return {...item, amount: cartItem.amount + 1};
        } else return item;
      });
      setCart(newCart);
    } else {
      setCart([...cart, newItem]);
    }
  };

  // remove from cart
  const removeFromCart = (id) => {
    const newCart = cart.filter((item) => {
      return item.id !== id;
    });
    setCart(newCart);
  };

  // cleart cart
  const clearCart = () => {
    setCart([]);
  };

  // increase amount
  const increaseAmount = (id) => {
    const cartItem = cart.find((item) => item.id === id);
    addToCart(cartItem, id);
  };

  // decrease amount
  const decreaseAmount = (id) => {
    const cartItem = cart.find((item) => item.id === id);
    if (cartItem) {
      const newCart = cart.map((item) => {
        if (item.id === id) {
          return {...item, amount: cartItem.amount - 1};
        } else {
          return item;
        }
      });
      setCart(newCart);
    }
    if (cartItem.amount < 2) {
      removeFromCart(id);
    }
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        addToCart,
        removeFromCart,
        clearCart,
        increaseAmount,
        decreaseAmount,
        itemAmount,
        total,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;
