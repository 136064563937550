import React, {createContext, useState, useContext, useEffect} from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";

export const DoorstepContext = createContext();

const DoorstepLocationsProvider = ({children}) => {
  // contains the current delivery price
  const [locations, setLocations] = useState([]); // all doorstep locations for PickupMtaani
  const [doorstep_location, setDoorstepLocation] = useState(""); // the specific doorstep location (e.g marurui or donholm)
  const [specific_location, setSpecificLocation] = useState(""); // filled once someone chooses the doorstep location. e.g marurui. like exact location for PickupMtaani
  const [email, setEmail] = useState(""); // Change to Phone number

  async function fetchLocations() {
    let response = await axios.get(
      "https://staging7.dev.pickupmtaani.com/api/doorstep-destinations",
    );
    setLocations(response.data);
  }

  // add the new location
  const newLocation = (newloc) => {
    setDoorstepLocation(newloc);
  };

  // add the new location
  const newSpecificLoc = (newloc) => {
    setSpecificLocation(newloc);
  };

  // add the new email
  const newEmail = (newloc) => {
    setEmail(newloc);
  };

  // update asyncstorage when states change
  useEffect(() => {
    if (doorstep_location) {
      AsyncStorage.setItem("my-doorstep-location", `${doorstep_location}`);
    }
  }, [doorstep_location]);

  // update asyncstorage when email changes
  useEffect(() => {
    if (email) {
      AsyncStorage.setItem("my-email", `${email}`);
    }
  }, [email]);

  useEffect(() => {
    if (specific_location) {
      AsyncStorage.setItem("my-specific-location", `${specific_location}`);
    }
  }, [specific_location]);

  async function fetchDoorstepLocation() {
    const value = await AsyncStorage.getItem("my-doorstep-location");
    if (value) {
      setDoorstepLocation(value);
    }
  }

  async function fetchEmail() {
    const value = await AsyncStorage.getItem("my-email");
    if (value) {
      setEmail(value);
    }
  }

  async function fetchSpecificLocation() {
    const value = await AsyncStorage.getItem("my-specific-location");
    if (value) {
      setSpecificLocation(value);
    }
  }

  useEffect(() => {
    fetchLocations();
  }, []);

  useEffect(() => {
    fetchDoorstepLocation();
    fetchSpecificLocation();
    fetchEmail();
  }, []);

  return (
    <DoorstepContext.Provider
      value={{
        locations,
        doorstep_location,
        specific_location,
        newLocation,
        newSpecificLoc,
        email,
        newEmail,
      }}
    >
      {children}
    </DoorstepContext.Provider>
  );
};

export default DoorstepLocationsProvider;
