import {useNavigate, Link} from "react-router-dom";
import axios from "axios";
import {useState, useEffect} from "react";

const Deliveries = () => {
  // mostly contain links to admin functionality. All admin endpoints are relevant here
  const navigate = useNavigate();
  const [deliveries, setDeliveries] = useState([]);
  const [input_open, setInputOpen] = useState(false);
  const [price, setPrice] = useState(0);
  const [name, setName] = useState("");
  const [is_doorstep, setDoorstep] = useState(false);

  async function fetchDeliveries() {
    try {
      let res = await axios.get("/fetch/deliveries");
      setDeliveries(res.data);
    } catch (e) {
      console.log(e);
    }
  }

  async function deleteDelivery(id) {
    try {
      let res = await axios.delete(`/delete/delivery/${id}`);
      await fetchDeliveries();
    } catch (e) {
      console.log(e);
    }
  }

  async function addDelivery(e) {
    e.preventDefault();
    if (price && name) {
      try {
        let res = await axios.post("/create/delivery", {
          name,
          price,
          is_doorstep,
        });
        fetchDeliveries();
      } catch (e) {
        console.log(e);
      }
    }
    // return error since category is not validated
  }

  useEffect(() => {
    fetchDeliveries();
  }, []);

  return (
    <div className="pt-[100px] md:pt-32">
      <p>Deliveries</p>

      {deliveries.map((cat) => (
        <div>
          <ul className="flex justify-around">
            <li key={cat.id}> {cat.name}</li>
            <li key={cat.id}> {cat.price}</li>
            <li key={cat.id}> {cat.is_doorstep}</li>
            <button onClick={() => deleteDelivery(cat.id)}> Delete </button>
          </ul>
        </div>
      ))}

      <button onClick={() => setInputOpen(!input_open)}> Add </button>

      {input_open ? (
        <div className="w-full max-w-xs">
          <form
            className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
            onSubmit={addDelivery}
          >
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                for="username"
              >
                Delivery Name
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                for="username"
              >
                Delivery Price
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                value={price}
                onChange={(e) => {
                  setPrice(e.target.value);
                }}
              />
            </div>
            <div className="mb-4">
              <input
                id="default-checkbox"
                type="checkbox"
                value={is_doorstep}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                onChange={() => setDoorstep(!is_doorstep)}
              />
              <label
                for="default-checkbox"
                className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                This is a doorstep kind of delivery
              </label>
            </div>
            <div className="flex items-center justify-between">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
              >
                Submit
              </button>
            </div>
          </form>
          <p className="text-center text-gray-500 text-xs">
            &copy;2020 Acme Corp. All rights reserved.
          </p>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default Deliveries;
