import {useNavigate, Link} from "react-router-dom";
import axios from "axios";
import {useState, useEffect} from "react";

const Orders = () => {
  // COntains the orders currently paid for
  const [orders, setOrders] = useState([]);

  async function fetchOrders() {
    try {
      let res = await axios.get("/new-orders");
      setOrders(res.data.payments);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <div className="pt-[100px] md:pt-32">
      <p>Here are the new orders</p>

      {/* bag name, bag quantity, then total amount paid. So inner join to bags and payments table*/}

      {orders.map((cat) => (
        <div>
          <ul className="flex justify-around">
            <li key={cat.payment_id}>
              Order{" "}
              <Link to={`/new-orders/${cat.payment_id}`}>
                {" "}
                {cat.payment_id}{" "}
              </Link>
            </li>
            <li key={cat.payment_id}> Payment {cat.MpesaReceiptNumber} </li>
          </ul>
        </div>
      ))}
    </div>
  );
};

export default Orders;
