import {LogContext} from "../contexts/LoggedContext";
import {useContext} from "react";
import {useNavigate, Link} from "react-router-dom";

const AdminHome = () => {
  // mostly contain links to admin functionality. All admin endpoints are relevant here
  const {user_username} = useContext(LogContext);
  const navigate = useNavigate();

  function LogUserOut() {
    // send logout request to server. Once successful, logout user on frontend
  }

  return (
    <div className="pt-[100px] md:pt-32">
      <p>Welcome {user_username}, here are a couple of quick links </p>

      <p>
        {" "}
        <Link to="/categories"> Bag Categories</Link>{" "}
      </p>
      <p>
        {" "}
        <Link to="/bags"> Bags</Link>{" "}
      </p>
      <p>
        {" "}
        <Link to="/deliveries"> Deliveries</Link>{" "}
      </p>
      <p>
        {" "}
        <Link to="/new-orders"> New Orders</Link>{" "}
      </p>

      <p className="">
        {" "}
        <Link to="/processing-orders"> Processing orders </Link>
      </p>
    </div>
  );
};

export default AdminHome;
