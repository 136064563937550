import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";

import Home from "./pages/Home";
import ProductDetails from "./pages/ProductDetails";
import Checkout from "./pages/Checkout";
import Login from "./pages/Login";
import AdminHome from "./pages/AdminHome";

import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import Footer from "./components/Footer";
import BagCategories from "./pages/BagCategories";
import Bags from "./pages/Bags";
import Deliveries from "./pages/Deliveries";
import BagDetails from "./pages/BagDetails";
import Orders from "./pages/Orders";
import DetailedOrders from "./pages/DetailedOrders";
import PaymentInfo from "./pages/PaymentInfo";
import Processing from "./pages/Processing";
import ProcessingDetailed from "./pages/ProcessingDetailed";
import axios from "axios";

if (window.location.origin === "http://localhost:3000" || window.location.origin === "http://127.0.0.1:3000") {
    axios.defaults.baseURL = "http://127.0.0.1:8000";
} else {
    axios.defaults.baseURL = `${window.location.origin}/api`;
}


const App = () => {
  return (
    <div className="overflow-hidden">
      <Router>
        <div className="min-h-screen flex flex-col">
          <div className="main flex-grow">
            <Header />
            <Routes>
              <Route path="/" element={<Home />}></Route>
              <Route path="/product/:id" element={<ProductDetails />}></Route>
              <Route path="/checkout" element={<Checkout />}></Route>
              <Route path="/admin-login" element={<Login />}></Route>
              <Route path="/admin-home" element={<AdminHome />}></Route>
              <Route path="/categories" element={<BagCategories />}></Route>
              <Route path="/bags" element={<Bags />}></Route>
              <Route path="/deliveries" element={<Deliveries />}></Route>
              <Route path="/bags/:id" element={<BagDetails />}></Route>
              <Route path="/new-orders" element={<Orders />}></Route>
              <Route
                path="/new-orders/:id"
                element={<DetailedOrders />}
              ></Route>
              <Route path="/payment/:id" element={<PaymentInfo />}></Route>
              <Route path="/processing-orders" element={<Processing />}></Route>
              <Route
                path="/processing-orders/:id"
                element={<ProcessingDetailed />}
              ></Route>
            </Routes>
            <Sidebar />
          </div>
          <Footer />
        </div>
      </Router>
    </div>
  );
};

export default App;
