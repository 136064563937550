import React, {createContext, useState, useContext, useEffect} from "react";
import {CartContext} from "./CartContext";
import AsyncStorage from "@react-native-async-storage/async-storage";

export const DelFeeContext = createContext();

const DeliveryFeeProvider = ({children}) => {
  // contains the current delivery price
  const {total} = useContext(CartContext); // represents the total product price
  const [delivery_fee, setDeliveryFee] = useState(0);
  const [checkedId, setCheckedId] = useState(0);

  const newDeliveryFee = (fee) => {
    if (total) {
      setDeliveryFee(fee);
    }
  };

  const newCheckedId = (id) => {
    setCheckedId(id);
  };

  // update asyncstorage when states change
  useEffect(() => {
    if (checkedId !== 0) {
      AsyncStorage.setItem("my-checkedId", `${checkedId}`);
    }
  }, [checkedId]);

  // update asyncstorage when states change
  useEffect(() => {
    if (delivery_fee !== 0) {
      AsyncStorage.setItem("my-deliveryFee", `${delivery_fee}`);
    }
  }, [delivery_fee]);

  async function fetchDeliveryFee() {
    const value = await AsyncStorage.getItem("my-deliveryFee");
    if (value) {
      setDeliveryFee(value);
    }
  }

  async function fetchCheckedId() {
    const value = await AsyncStorage.getItem("my-checkedId");
    if (value) {
      setCheckedId(value);
    }
  }

  useEffect(() => {
    fetchDeliveryFee();
    fetchCheckedId();
  }, []);

  return (
    <DelFeeContext.Provider
      value={{delivery_fee, newDeliveryFee, checkedId, newCheckedId}}
    >
      {children}
    </DelFeeContext.Provider>
  );
};

export default DeliveryFeeProvider;
