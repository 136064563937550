import React, {createContext, useState, useEffect} from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";

export const LogContext = createContext();

const LogProvider = ({children}) => {
  // once login is successful update the logged and username

  const [logged, setLogged] = useState(false);
  const [user_username, setUserName] = useState("");
  const [user_email, setEmail] = useState("");
  const [id, setID] = useState(0);

  const setLoggedFunc = (cond) => {
    setLogged(cond);
  };

  const setUserNameFunc = (name) => {
    if (name) {
      setUserName(name);
    }
  };

  const setEmailFunc = (mail) => {
    if (mail) {
      setEmail(mail);
    }
  };

  // update asyncstorage when states change
  useEffect(() => {
    if (logged !== null) {
      AsyncStorage.setItem("my-logged", `${logged}`);
    }
  }, [logged]);

  // update asyncstorage when states change
  useEffect(() => {
    if (user_email) {
      AsyncStorage.setItem("my-useremail", `${user_email}`);
    }
  }, [user_email]);

  // update asyncstorage when states change
  useEffect(() => {
    if (user_username) {
      AsyncStorage.setItem("my-userusername", `${user_username}`);
    }
  }, [user_username]);

  // update asyncstorage when states change
  useEffect(() => {
    if (id) {
      AsyncStorage.setItem("my-id", `${id}`);
    }
  }, [id]);

  const setIdFunc = (id) => {
    if (id) {
      setID(id);
    }
  };

  async function fetchEmail() {
    const value = await AsyncStorage.getItem("my-useremail");
    if (value) {
      setEmail(value);
    }
  }

  async function fetchUsername() {
    const value = await AsyncStorage.getItem("my-userusername");
    if (value) {
      setUserName(value);
    }
  }

  async function fetchID() {
    const value = await AsyncStorage.getItem("my-id");
    if (value) {
      setID(value);
    }
  }

  async function fetchLogged() {
    const value = await AsyncStorage.getItem("my-logged");
    if (value) {
      setLogged(value);
    }
  }

  useEffect(() => {
    fetchEmail();
    fetchID();
    fetchLogged();
    fetchUsername();
  }, []);

  return (
    <LogContext.Provider
      value={{
        logged,
        setLogged,
        user_username,
        setUserName,
        user_email,
        setEmail,
        id,
        setID,
        setLoggedFunc,
        setEmailFunc,
        setIdFunc,
        setUserNameFunc,
      }}
    >
      {children}
    </LogContext.Provider>
  );
};

export default LogProvider;
