import {Link, useParams} from "react-router-dom";
import axios from "axios";
import {useState, useEffect} from "react";

const BagDetails = () => {
  // mostly contain links to admin functionality. All admin endpoints are relevant here
  const [bags, setBags] = useState([]);
  const [image, setImage] = useState();
  const searchParams = useParams();

  async function fetchBags() {
    try {
      let res = await axios.get(`/bags/${searchParams.id}`);
      setBags(res.data);
    } catch (e) {
      console.log(e);
    }
  }

  async function deleteBag(id) {
    try {
      let res = await axios.delete(`/delete/bag/${id}`);
      await fetchBags();
    } catch (e) {
      console.log(e);
    }
  }

  function handleChange(e) {
    setImage(e.target.files[0]);
  }

  async function uploadPic(e) {
    e.preventDefault();
    try {
      const form = new FormData();
      form.append("file", image);
      await axios.post(`/upload-pic/pic/${searchParams.id}`, form, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      fetchBags();
    } catch (e) {
      console.log(e.response);
    }
  }

  async function changePic(res, bag_id) {
    try {
      const form = new FormData();
      form.append("file", image);
      await axios.put(`/edit-pic/pic/${bag_id}?bag_loc=${res}`, form, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      fetchBags();
    } catch (e) {
      console.log(e.response);
    }
  }

  useEffect(() => {
    fetchBags();
  }, []);

  return (
    <div className="pt-[100px] md:pt-32">
      <p>Bags</p>

      <div>
        <ul className="flex flex-col">
          <li key={bags.id}>{bags.name}</li>
          <li key={bags.id}> {bags.price}</li>
          <li key={bags.id}> {bags.quantity}</li>
          <li key={bags.id}> {bags.bagsegory_id}</li>
          {bags.pics &&
            bags.pics.map((res) => (
              <div>
                <li>
                  <img src={res} />;
                </li>
                <form
                  className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
                  onSubmit={(e) => {
                    e.preventDefault();
                    changePic(res, bags.id);
                  }}
                >
                  <div className="mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      for="username"
                    >
                      File
                    </label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      type="file"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="flex items-center justify-between">
                    <button
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      type="submit"
                    >
                      Update Image
                    </button>
                  </div>
                </form>
              </div>
            ))}
          <button onClick={() => deleteBag(bags.id)}> Delete </button>
        </ul>

        <p>Upload New Picture</p>

        <form
          className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
          onSubmit={uploadPic}
        >
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              for="username"
            >
              File
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="file"
              onChange={handleChange}
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BagDetails;
