import AsyncStorage from "@react-native-async-storage/async-storage";
import React, {createContext, useState, useContext, useEffect} from "react";
import {CartContext} from "./CartContext";
import {DelFeeContext} from "./DeliveryFeeContext";

export const PriceContext = createContext();

const PriceProvider = ({children}) => {
  // contains the current total price, which will be changed in the checkout page depending on the delivery id chosen
  const {total} = useContext(CartContext); // represents the total product price
  const [prices, setPrices] = useState(0);
  const {delivery_fee} = useContext(DelFeeContext);

  const calculateTotalPrice = () => {
    if (total) {
      setPrices(parseInt(total) + parseInt(delivery_fee));
    }
  };

  // update asyncstorage when states change
  useEffect(() => {
    if (prices !== 0) {
      AsyncStorage.setItem("my-prices", `${prices}`);
    }
  }, [prices]);

  async function fetchPrices() {
    const value = await AsyncStorage.getItem("my-prices");
    if (value) {
      setPrices(value);
    }
  }

  useEffect(() => {
    calculateTotalPrice();
  }, [delivery_fee, total]);

  useEffect(() => {
    fetchPrices();
  }, []);

  return (
    <PriceContext.Provider value={{prices}}>{children}</PriceContext.Provider>
  );
};

export default PriceProvider;
