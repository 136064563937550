import React, {useContext, useState, useEffect} from "react";
import {DeliveryContext} from "../contexts/DeliveriesContext";
import {PriceContext} from "../contexts/TotalPriceContext";
import {CartContext} from "../contexts/CartContext";
import {DelFeeContext} from "../contexts/DeliveryFeeContext";
import {DoorstepContext} from "../contexts/DoorstepLocationsContext";
import axios from "axios";
import CartItem from "../components/CartItem";

const Checkout = () => {
  const {deliveries} = useContext(DeliveryContext);
  const {prices} = useContext(PriceContext);
  // we need to display cart summary again here, show delivery options from which to choose. We can now choose deliveries. Now we implement picker functionality. Now we add another context that contains the total price that includes delivery.
  const {total, cart, clearCart, itemAmount} = useContext(CartContext);
  const {delivery_fee, newDeliveryFee, checkedId, newCheckedId} =
    useContext(DelFeeContext);
  const {
    locations,
    doorstep_location,
    newLocation,
    newSpecificLoc,
    specific_location,
    email,
    newEmail,
  } = useContext(DoorstepContext);
  const [doorstep_chosen, setDoorstepChosen] = useState(false);
  const [payment_phone, setPaymentPhone] = useState("");

  function checkClicked(price, id) {
    newDeliveryFee(price);
    newCheckedId(id);

    // check if the delivery is is_doorstep and then use this to show the drop down and doorstep locations.
    let delivery = deliveries.filter((dl) => dl.id == id);
    if (delivery[0].is_doorstep == true) {
      setDoorstepChosen(true);
    } else {
      setDoorstepChosen(false);
    }
  }

  function checkDoorstepChosen() {
    let delivery = deliveries.filter((dl) => dl.id == checkedId);
    if (delivery.length && delivery[0].is_doorstep == true) {
      setDoorstepChosen(true);
    } else {
      setDoorstepChosen(false);
    }
  }

  function newExact(e) {
    newSpecificLoc(e.target.value);
  }

  useEffect(() => {
    // currently not working
    checkDoorstepChosen();
  }, []);

  async function sendPayment(e) {
    e.preventDefault();
    let product_details = [];
    for (let crt of cart) {
      let obj = {};
      obj.id = crt.id;
      obj.quantity = crt.amount;
      product_details.push(obj);
    }

    if (product_details.length) {
      let response = await axios.post("/create/order", {
        location: doorstep_location,
        product_details,
        delivery_id: checkedId,
        exact_location: specific_location,
        payment_number: payment_phone,
        customer_number: email,
      });

      console.log(response);
      // if successful clear cart and inform user of success. Else show failure
    }
  }

  return (
    <div className="pt-[100px] md:pt-32">
      <div className="flex justify-around mx-3 bg-gray-200 border border-gray-300 mb-8">
        <p> Order Summary</p>
        <p> {total} ksh</p>
      </div>

      <div class="grid gap-6 mb-6 md:grid-cols-2">
        <div className="flex flex-col items-center justify-center">
          <label
            for="first_name"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white ml-3"
          >
            Phone Number
          </label>
          <input
            value={email}
            type="text"
            id="contact"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="input contact"
            required
            onChange={(e) => newEmail(e.target.value)}
          />
        </div>
      </div>

      <p className="flex justify-center block mb-2 text-lg font-medium text-gray-900 dark:text-white ml-3">
        Deliveries
      </p>

      {deliveries.map((delivery) => (
        <div class="relative flex flex-col text-gray-700 bg-white shadow-md rounded-xl bg-clip-border">
          <nav class="flex min-w-[240px] flex-col gap-1 p-2 font-sans text-base font-normal text-blue-gray-700">
            <div
              role="button"
              class="flex items-center w-full p-0 leading-tight transition-all rounded-lg outline-none text-start hover:bg-blue-gray-50 hover:bg-opacity-80 hover:text-blue-gray-900 focus:bg-blue-gray-50 focus:bg-opacity-80 focus:text-blue-gray-900 active:bg-blue-gray-50 active:bg-opacity-80 active:text-blue-gray-900"
            >
              <label
                htmlFor="vertical-list-react"
                class="flex items-center w-full px-3 py-2 cursor-pointer"
              >
                <div class="grid mr-3 place-items-center">
                  <div class="inline-flex items-center">
                    <label
                      class="relative flex items-center p-0 rounded-full cursor-pointer"
                      htmlFor="vertical-list-react"
                    >
                      {delivery.id == checkedId ? (
                        <input
                          name="vertical-list"
                          id={delivery.id}
                          type="radio"
                          class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 text-gray-900 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:before:bg-gray-900 hover:before:opacity-0"
                          onClick={() =>
                            checkClicked(delivery.price, delivery.id)
                          }
                          checked
                        />
                      ) : (
                        <input
                          name="vertical-list"
                          id={delivery.id}
                          type="radio"
                          class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 text-gray-900 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:before:bg-gray-900 hover:before:opacity-0"
                          onClick={() =>
                            checkClicked(delivery.price, delivery.id)
                          }
                        />
                      )}
                      <span class="absolute text-gray-900 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-3.5 w-3.5"
                          viewBox="0 0 16 16"
                          fill="currentColor"
                        >
                          <circle
                            data-name="ellipse"
                            cx="8"
                            cy="8"
                            r="8"
                          ></circle>
                        </svg>
                      </span>
                    </label>
                  </div>
                </div>
                <p class="block font-sans text-base antialiased font-medium leading-relaxed text-blue-gray-400">
                  {delivery.name}
                </p>
              </label>
            </div>
          </nav>
          {/* drop down of locations should come here  */}
          {delivery.is_doorstep && doorstep_chosen ? (
            <div className="flex flex-col justify-center items-center">
              <form className="max-w-sm mx-auto">
                <label
                  for="countries"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Select a location
                </label>
                <select
                  id="countries"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option selected>
                    {doorstep_location || "Choose a location"}
                  </option>
                  {locations.map((lc) => (
                    <option
                      value={lc.name}
                      onClick={() => newLocation(lc.name)}
                    >
                      {lc.name}
                    </option>
                  ))}
                </select>
                {doorstep_location && (
                  <div className="max-w-sm mx-auto">
                    <label
                      for="first_name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white mt-2"
                    >
                      Exact Location
                    </label>
                    <input
                      value={specific_location}
                      type="text"
                      id="first_name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="input exact location"
                      required
                      onChange={newExact}
                    />
                  </div>
                )}
              </form>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      ))}

      <p className="flex justify-center block mb-2 text-lg font-medium text-gray-900 dark:text-white ml-3 mt-5">
        Order Summary
      </p>

      <div className="flex flex-col">
        <div className="flex justify-between mx-4">
          <p> Products Price </p>
          <p> {total} </p>
        </div>

        <div className="flex justify-between mx-4">
          <p> Delivery Price </p>
          <p> {delivery_fee} </p>
        </div>

        <div className="flex justify-between mx-4">
          <p> Total Price </p>
          <p> {prices} </p>
        </div>
      </div>

      <form className="max-w-sm mx-auto mt-6 mb-8" onSubmit={sendPayment}>
        <div className="mb-5">
          <label
            for="email"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white flex justify-center"
          >
            Enter Payment Phone Number
          </label>
          <input
            value={payment_phone}
            type="text"
            id="phonenumber"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required
            onChange={(e) => setPaymentPhone(e.target.value)}
          />
        </div>

        <button
          type="submit"
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Pay
        </button>
      </form>
    </div>
  );
};

export default Checkout;
