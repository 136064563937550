import axios from "axios";
import {useState, useContext} from "react";
import {tokenSet} from "./setToken";
import {LogContext} from "../contexts/LoggedContext";
import {useNavigate} from "react-router-dom";

const Login = () => {
  // const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const {
    logged,
    setLogged,
    user_username,
    setUserName,
    user_email,
    setEmail,
    id,
    setID,
  } = useContext(LogContext);
  const navigate = useNavigate();

  async function sendLogin(e) {
    e.preventDefault();
    // should be sent as a form

    try {
      let res = await axios.post(
        "/login",
        {
          username: user_email,
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        },
      );

      // save token to localstorage and to Authorization header in axios
      await tokenSet(res.data.access_token);
      setLogged(true);
      setEmail(res.data.email);
      setUserName(res.data.username);
      setID(res.data.id);
      navigate("/admin-home");
    } catch (e) {
      console.log(e);
    }

    // then redirect to admin-home. Which should only allow logged people
  }

  return (
    <div className="pt-[100px] md:pt-32">
      <p className="flex justify-center text-3xl">
        {" "}
        Admin Login, {user_username}{" "}
      </p>

      <form class="max-w-sm mx-auto" onSubmit={sendLogin}>
        <div class="mb-5">
          <label
            for="email"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Your email
          </label>
          <input
            value={user_email}
            type="email"
            id="email"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="name@flowbite.com"
            required
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div class="mb-5">
          <label
            for="password"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Your password
          </label>
          <input
            value={password}
            type="password"
            id="password"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div class="flex items-start mb-5">
          <div class="flex items-center h-5">
            <input
              id="remember"
              type="checkbox"
              value=""
              class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
              required
            />
          </div>
          <label
            for="remember"
            class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Remember me
          </label>
        </div>
        <button
          type="submit"
          class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default Login;
